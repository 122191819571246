import { getRunMode } from '@src/utils'

export const GRPC_API_URL = (
  {
    prod: 'https://grpc.socar.kr',
    dev: 'https://grpc.socar.me',
    stg: 'https://grpc-stg.socar.kr',
  } as const
)[getRunMode()]

export const HOST_URL = (
  {
    prod: 'https://stay-event.socar.kr',
    dev: 'https://stay-event.socar.me',
    stg: 'https://stay-event-stg.socar.me',
  } as const
)[getRunMode()]

import { DefaultSeo } from 'next-seo'
import Head from 'next/head'
import { Fragment } from 'react'
import DOMPurify from 'isomorphic-dompurify'

export const DefaultHeader = () => {
  const gaId = 'G-S9DRH0HBNL'

  const gaScript = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config', '${gaId}');
`

  return (
    <Fragment>
      <Head>
        <DefaultSeo
          title="쏘카 숙박 이벤트"
          openGraph={{
            siteName: '쏘카',
          }}
        />
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE-Edge" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1, user-scalable=0"
        />
        <link
          rel="preload"
          href="/fonts/SpoqaHanSansNeoRegular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          fetchPriority="high"
        />
        <link
          rel="preload"
          href="/fonts/SpoqaHanSansNeoMedium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          fetchPriority="high"
        />
        <link
          rel="preload"
          href="/fonts/SpoqaHanSansNeoBold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
          fetchPriority="high"
        />
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(gaScript),
          }}
        />
      </Head>
    </Fragment>
  )
}

export const runModes = ['stg', 'prod', 'dev'] as const

export type RunMode = (typeof runModes)[number]

export const checkRunMode = (value: string = ''): value is RunMode => {
  const values: string[] = [...runModes]
  return values.includes(value)
}

export const getRunMode = () => {
  if (checkRunMode(process.env.NEXT_PUBLIC_RUN_MODE))
    return process.env.NEXT_PUBLIC_RUN_MODE
  return 'dev'
}

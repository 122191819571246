import { DefaultHeader } from '@src/components'
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { type AppProps } from 'next/app'
import { useState } from 'react'
import { RecoilRoot } from 'recoil'
import '../styles/global.css'
import { TransportProvider } from '@connectrpc/connect-query'
import { transport } from '@src/api'
import { initRum } from '@socar-inc/utils'
import { GRPC_API_URL } from '@src/constants'
import { getRunMode } from '@src/utils'

initRum({
  config: {
    applicationId: process.env.NEXT_PUBLIC_RUM_APPID ?? '',
    clientToken: process.env.NEXT_PUBLIC_RUM_TOKEN ?? '',
    service: 'stay-event-frontend',
    env: getRunMode(),
    allowedTracingUrls: [GRPC_API_URL],
  },
  enabled: process.env.NODE_ENV === 'production',
})

function MyApp({ Component, pageProps }: AppProps) {
  const [queryClient] = useState(() => new QueryClient())

  return (
    <TransportProvider transport={transport}>
      <QueryClientProvider client={queryClient}>
        <HydrationBoundary state={pageProps.dehydratedState}>
          <RecoilRoot>
            <DefaultHeader />
            <div className="tw-flex tw-w-full tw-flex-col tw-items-center">
              <Component {...pageProps} />
            </div>
            <ReactQueryDevtools
              initialIsOpen={process.env.NODE_ENV === 'development'}
            />
          </RecoilRoot>
        </HydrationBoundary>
      </QueryClientProvider>
    </TransportProvider>
  )
}

export default MyApp

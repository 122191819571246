import { createGrpcWebTransport } from '@connectrpc/connect-web'
import { GRPC_API_URL } from '@src/constants'
import type { Interceptor } from '@connectrpc/connect'
import { getAccessToken, isClientSide } from '@socar-inc/utils'

declare global {
  interface Window {
    __CONNECT_WEB_DEVTOOLS__: Interceptor
  }
}

export const accessToken = isClientSide ? getAccessToken() : ''

const headerInterceptor: Interceptor = (next) => (req) => {
  req.header.delete('connect-protocol-version')
  req.header.set('Authorization', `Bearer ${accessToken}`)
  return next(req)
}

const interceptors: Interceptor[] =
  isClientSide &&
  process.env.NODE_ENV === 'development' &&
  window.__CONNECT_WEB_DEVTOOLS__
    ? [window.__CONNECT_WEB_DEVTOOLS__, headerInterceptor]
    : [headerInterceptor]

export const transport = createGrpcWebTransport({
  baseUrl: GRPC_API_URL,
  interceptors: interceptors,
  useBinaryFormat: true,
})
